import * as React from "react"
import { Link } from "gatsby"
import Cart from "../icons/cart.svg"
import { css } from "@emotion/react"

export function CartButton({ quantity }) {
    return (
        <Link aria-label={`Shopping Cart with ${quantity} items`} to="/cart" css={cartLink}>
            {quantity > 0 && <p css={quantityStyle}>{quantity}</p>}
            <img src={Cart} width="30px" height="30px" alt="cart" css={cartIcon} />
        </Link>
    )
}

const cartLink = css`
    text-align: center;
`
const quantityStyle = css`
    line-height: 1rem;
    transform: translateY(4px);
`
const cartIcon = css`
    transform: translateY(4px);
`
