import React, { useRef, useState } from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "./logo"
import { CartButton } from "./cart-button"
import LanguageButton from "./languageButton"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import { motion, AnimatePresence } from "framer-motion"

const list = {
    hidden: {
        y: 20,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
    },
    exit: {
        y: -20,
        opacity: 0,
    },
}

const transition = { ease: [0.455, 0.03, 0.515, 0.955] }

export function HeaderSp() {
    const { checkout } = React.useContext(StoreContext)

    const items = checkout ? checkout.lineItems : []

    const quantity = items.reduce((total, item) => {
        return total + item.quantity
    }, 0)

    const contentRef = useRef(null)
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const navlist = [
        { path: "/", name: "Top" },
        { path: "/shop/", name: "Shop" },
        { path: "/concept/", name: "Concept" },
        { path: "/creators/", name: "Creators" },
        { path: "/gallery/", name: "Gallery" },
        { path: "/contact/", name: "Contact" },
    ]

    return (
        <header css={header}>
            <div
                css={hamburger}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                onKeyDown={() => setIsMenuOpen(!isMenuOpen)}
                role="button"
                tabIndex={0}
            >
                <AnimatePresence>
                    <motion.span
                        css={menuBar}
                        animate={{ rotate: isMenuOpen ? 45 : 0, y: isMenuOpen ? 0 : -5 }}
                        transition={{ ...transition, duration: 0.2 }}
                        key="span1"
                    ></motion.span>
                    <motion.span
                        css={menuBar}
                        animate={{ rotate: isMenuOpen ? -45 : 0, y: isMenuOpen ? 0 : 5 }}
                        transition={{ ...transition, duration: 0.2 }}
                        key="span2"
                    ></motion.span>
                </AnimatePresence>
            </div>
            <Link to="/" css={logoLink}>
                <Logo />
            </Link>
            <div css={cartIcon}>
                <CartButton quantity={quantity} />
            </div>
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.nav
                        css={innerContainer}
                        ref={contentRef}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transiton={{ duration: 0.4 }}
                        key="nav"
                    >
                        <motion.ul
                            css={mainUl}
                            variants={list}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            transition={{ ...transition, duration: 0.55 }}
                        >
                            {navlist.map((list) => (
                                <motion.li css={mainLi} key={list.name}>
                                    <Link
                                        to={list.path}
                                        css={navLink}
                                        onClick={() => setIsMenuOpen(false)}
                                        activeStyle={{ borderBottom: `solid 1px black` }}
                                    >
                                        {list.name}
                                    </Link>
                                </motion.li>
                            ))}
                        </motion.ul>
                        <motion.ul
                            css={bottomUl}
                            variants={list}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            transition={{ ...transition, duration: 0.55, delay: 0.3 }}
                        >
                            <li css={buttons}>
                                <LanguageButton />
                            </li>
                        </motion.ul>
                    </motion.nav>
                )}
            </AnimatePresence>
        </header>
    )
}

const header = css`
    display: none;

    @media ${device.tb} {
        display: block;
        width: 100%;
        height: 50px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        background: white;
    }
`
const innerContainer = css`
    width: calc(100% - 20vmin);
    height: 80vh;
    padding: 10vh 10vw;
    background: white;
`
const hamburger = css`
    position: absolute;
    width: 30px;
    height: 30px;
    left: 7%;
    top: 10px;
    cursor: pointer;
`
const menuBar = css`
    width: 30px;
    height: 1px;
    display: block;
    background: #2b2b2b;
    position: absolute;
    top: 15px;
    left: 0;
    transition: 0.3s ease-in-out;
`
const logoLink = css`
    position: fixed;
    top: 22px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: min(25%, 150px);
`
const cartIcon = css`
    position: fixed;
    top: 26px;
    right: 2%;
    transform: translate(-50%, -50%);
`
const mainUl = css`
    margin-bottom: 10vh;
    height: auto;
`
const mainLi = css`
    margin: 5vh 0;
`
const navLink = css`
    padding: 0 0 2px;
    display: inline-block;
    font-size: 2.2rem;
`
const bottomUl = css``
const buttons = css`
    margin: 20px auto;
    & > * > * {
        font-size: 1.8rem !important;
    }
`
