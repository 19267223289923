import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import Logo from "./logo"
import { device } from "../styles/device"
import { InstagramIcon } from "./instagram"

export function Footer() {
    const intl = useIntl()

    const data = useStaticQuery(graphql`
        query {
            allContentfulShoppingGuide {
                nodes {
                    guide1
                    guide2
                }
            }
        }
    `)
    const translation =
        intl.locale === "ja" ? data.allContentfulShoppingGuide.nodes[0] : data.allContentfulShoppingGuide.nodes[1]

    const guideList = [translation.guide1, translation.guide2]

    const navlist = [
        { path: "/shop/", name: "Shop" },
        { path: "/concept/", name: "Concept" },
        { path: "/creators/", name: "Creators" },
        { path: "/gallery/", name: "Gallery" },
        { path: "/contact/", name: "Contact" },
    ]

    return (
        <footer css={footer}>
            <div css={footerContainer}>
                <Link to="/" css={logoLink}>
                    <Logo css={logo} />
                </Link>
                <div css={footerInner}>
                    <ul>
                        {navlist.map((list) => (
                            <li key={list.name}>
                                <Link to={list.path}>{list.name}</Link>
                            </li>
                        ))}
                    </ul>
                    <ul>
                        {guideList.slice(0, 2).map((guide, i) => (
                            <li key={guide}>
                                <Link to={`/guide#guide${i + 1}`}>{guide}</Link>
                            </li>
                        ))}
                        <li key="instagram-icon" css={SNSLink}>
                            <InstagramIcon />
                        </li>
                    </ul>
                </div>
            </div>
            <div css={copyright}>&copy; {new Date().getFullYear()} hadawa All rights reserved.</div>
        </footer>
    )
}
const footer = css`
    padding: 80px 10vw 20px;

    @media ${device.tb} {
        padding: 40px 7vw 20px;
    }
    @media ${device.sp} {
        padding: 6vmax 10vw 2vmax;
    }
`
const footerContainer = css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    @media ${device.sp} {
        display: block;
    }
`
const logoLink = css`
    width: 13%;
    @media ${device.sp} {
        width: 30vmin;
        display: inline-block;
        margin-bottom: 5vmax;
    }
`
const footerInner = css`
    display: flex;
    justify-content: space-between;
    width: 50%;
    line-height: 3.2rem;

    @media ${device.sp} {
        display: block;
        width: 100%;
        ul {
            line-height: 4.8rem;
            margin-bottom: 5vmax;
        }
    }
`
const logo = css`
    width: 100px;
`
const copyright = css`
    text-align: center;
    font-size: 1rem;
`
const SNSLink = css`
    margin-top: 3rem;
    a {
        &:hover {
            opacity: 0.5;
        }
    }
`
