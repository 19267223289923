import React from "react"
import { Header } from "./header"
import { HeaderSp } from "./headerSp"
import { Footer } from "./footer"
import { Seo } from "./seo"
import { Global, css } from "@emotion/react"
import { device } from "../styles/device"

export function Layout({ children }) {
    return (
        <>
            <Seo />
            <Global styles={global} />
            <div css={globalWrapper}>
                <Header />
                <HeaderSp />
                {children}
                <Footer />
            </div>
        </>
    )
}
const globalWrapper = css`
    overflow-x: hidden;
`
const global = css`
    * {
        margin: 0;
        padding: 0;
    }
    html,
    body {
        width: 100%;
        background: #fff;
        color: #000;
        /* scroll-behavior: smooth; */
        font-display: swap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        text-align: justify;
        font-kerning: none;
        font-feature-settings: "chws" 1;
        line-break: strict;
        font-family: "Avenir", "YuGothic", "游ゴシック体", "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
            "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, sans-serif;
        text-align: left;
    }
    html {
        font-size: 62.5%;
    }
    body {
        font-size: 1.5rem;
        margin-top: 68px; /*height of menu bar*/
        @media ${device.sp} {
            margin-top: 50px;
        }
    }
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-size: 2.8rem;
    }
    h3 {
        font-size: 2.4rem;
    }
    h4 {
        font-size: 2.2rem;
    }
    h5 {
        font-size: 2rem;
    }
    h6 {
        font-size: 1.8rem;
    }
    p,
    a {
        font-size: 1.55rem;
        line-height: 2.6rem;
        font-weight: 200;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 200;
        font-feature-settings: "halt" 1;
        font-kerning: normal;
    }
    a {
        color: #000;
        transition: 0.5s cubic-bezier(0.6, -0.05, 0.01, 0.9);
        cursor: pointer;
        text-decoration: none;
        font-feature-settings: "halt" 1;
        font-kerning: normal;
        &:hover {
            /* opacity: 0.5; */
            text-decoration: none;
        }
    }
    li {
        list-style: none;
    }
    svg {
        transition: 0.5s cubic-bezier(0.6, -0.05, 0.01, 0.9);
    }
    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
        transition: 0.5s cubic-bezier(0.6, -0.05, 0.01, 0.9);
    }
    button,
    input,
    textarea,
    select {
        font-family: "Avenir", "YuGothic", "游ゴシック体", "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
            "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, sans-serif;
        letter-spacing: 0.07rem;
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-radius: none;
    }
`
