import * as React from "react"
import Instagram from "../icons/instagram.svg"

export function InstagramIcon() {
    return (
        <a href="https://www.instagram.com/hadawa_official/" target="_blank" rel="noreferrer">
            <img src={Instagram} width="24px" height="24px" alt="instagram icon" />
        </a>
    )
}
