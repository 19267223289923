import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"
import { css } from "@emotion/react"

const languageName = {
    en: "English",
    ja: "日本語",
}

const Language = () => {
    return (
        <div css={langContainer}>
            <IntlContextConsumer>
                {({ languages, language: currentLocale }) =>
                    languages.map((language, i) => (
                        <button
                            key={language}
                            onClick={() => changeLocale(language)}
                            onKeyDown={() => changeLocale(language)}
                            tabIndex={i}
                            disabled={currentLocale === language}
                            style={{
                                pointerEvents: currentLocale === language ? "none" : "auto",
                            }}
                        >
                            <span style={{ color: currentLocale === language ? `#969696` : `black` }} css={button}>
                                {languageName[language]}
                            </span>
                        </button>
                    ))
                }
            </IntlContextConsumer>
        </div>
    )
}

export default Language

const langContainer = css`
    @media ${device.sp} {
        a:nth-of-type(1) {
            margin: 0 4rem 0 0;
        }
        a:nth-last-of-type(1) {
            margin: 0;
        }
    }
`
const button = css`
    margin: 0.5rem;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.33rem;

    @media ${device.sp} {
        font-size: 1.55rem;
    }
`
