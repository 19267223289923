import * as React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const NavList = () => {
    return (
        <>
            <Link key="item" to="/shop" css={navLink} activeStyle={{ borderBottom: `solid 1px black` }}>
                Shop
            </Link>
            <Link key="products" to="/concept" css={navLink} activeStyle={{ borderBottom: `solid 1px black` }}>
                Concept
            </Link>
            <Link key="creators" to="/creators" css={navLink} activeStyle={{ borderBottom: `solid 1px black` }}>
                Creators
            </Link>
            <Link key="gallery" to="/gallery" css={navLink} activeStyle={{ borderBottom: `solid 1px black` }}>
                Gallery
            </Link>
        </>
    )
}
export default NavList

const navLink = css`
    margin: 0 14px;
    padding: 0;
    transition: none;
    &:hover {
        border-bottom: solid 1px black;
    }
`
