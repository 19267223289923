import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

function Logo() {
    return <StaticImage src="../../static/logo.png" alt="logo" loading="eager" objectFit="contain" css={logo} />
}
export default Logo

const logo = css`
    vertical-align: middle !important;
`
