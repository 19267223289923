import * as React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import { useIntl } from "gatsby-plugin-react-intl"
import { device } from "../styles/device"

const OrderNowButton = () => {
    const intl = useIntl()

    return (
        <Link to="/shop/" css={order}>
            {intl.formatMessage({ id: "order_now" })}
        </Link>
    )
}
export default OrderNowButton

const order = css`
    color: white;
    background: black;
    border: solid 1px black;
    border-radius: 2px;
    letter-spacing: 0.07rem;
    padding: 0.8rem 2rem;
    font-size: 1.33rem;
    position: relative;

    &:hover {
        background: transparent;
        color: black;
        border: solid 1px black;
    }

    @media ${device.sp} {
        font-size: 1.55rem;
        display: inline-block;
        width: 52vw;
        line-height: 4rem;
    }
`
