import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StoreContext } from "../context/store-context"
import Logo from "./logo"
import { CartButton } from "./cart-button"
import LanguageButton from "./languageButton"
import { css } from "@emotion/react"
import { device } from "../styles/device"
import OrderNowButton from "./orderNowButton"
import NavList from "./NavList"
import { motion } from "framer-motion"
import { useLocation } from "@reach/router"

const MotionNav = () => {
    const headerMotion = {
        initial: {
            opacity: 0,
            y: -10,
        },
        animate: {
            opacity: 1,
            y: 0,
        },
    }
    const transition = { duration: 0.8, ease: [0.6, -0.05, 0.01, 0.9] }

    const { checkout } = React.useContext(StoreContext)

    const items = checkout ? checkout.lineItems : []

    const quantity = items.reduce((total, item) => {
        return total + item.quantity
    }, 0)

    return (
        <>
            <motion.div
                css={logoContainer}
                variants={headerMotion}
                initial="initial"
                animate="animate"
                transition={transition}
            >
                <Link to="/" css={logoLink}>
                    <Logo css={logo} />
                </Link>
            </motion.div>
            <div css={innerContainer}>
                <motion.nav
                    variants={headerMotion}
                    initial="initial"
                    animate="animate"
                    transition={{ ...transition, delay: 0.3 }}
                >
                    <NavList />
                </motion.nav>
                <motion.div
                    initial="initial"
                    animate="animate"
                    variants={headerMotion}
                    transition={{ ...transition, delay: 0.5 }}
                >
                    <div css={rightContent}>
                        <div css={order}>
                            <OrderNowButton />
                        </div>
                        <div css={cartIconStyle}>
                            <CartButton quantity={quantity} />
                        </div>
                        <LanguageButton />
                    </div>
                </motion.div>
            </div>
        </>
    )
}

const NormalNav = () => {
    const { checkout } = React.useContext(StoreContext)

    const items = checkout ? checkout.lineItems : []

    const quantity = items.reduce((total, item) => {
        return total + item.quantity
    }, 0)

    return (
        <>
            <div css={logoContainer}>
                <Link to="/" css={logoLink}>
                    <Logo css={logo} />
                </Link>
            </div>
            <div css={innerContainer}>
                <nav>
                    <NavList />
                </nav>

                <div css={rightContent}>
                    <div css={order}>
                        <OrderNowButton />
                    </div>
                    <div css={cartIconStyle}>
                        <CartButton quantity={quantity} />
                    </div>
                    <LanguageButton />
                </div>
            </div>
        </>
    )
}

export function Header() {
    const { pathname } = useLocation()
    const [isRoot, setIsRoot] = useState(false)

    useEffect(() => {
        setIsRoot(pathname.replace(/\/$/, "") === "/en" || pathname === "/" ? true : false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <header css={header}>{isRoot ? <MotionNav /> : <NormalNav />}</header>
}

const header = css`
    width: calc(100% - 5vw);
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    padding: 15px 2.5vw;
    /* box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.03); */

    @media ${device.tb} {
        display: none;
    }
`
const innerContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const logoContainer = css`
    position: absolute;
    top: 20px;
    left: calc(50% - 50px);
    width: 100px;
`
const logoLink = css`
    width: 100%;
`
const logo = css`
    height: auto;
`
const rightContent = css`
    display: flex;
    align-items: center;
`
const order = css`
    margin-right: 24px;
`
const cartIconStyle = css`
    margin-right: 20px;
`
